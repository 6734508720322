var $ = require("jquery");
import 'slick-carousel/slick/slick';

(function($) {
	$.fn.clickToggle = function(func1, func2) {
		var funcs = [func1, func2];
		this.data('toggleclicked', 0);
		this.click(function() {
			var data = $(this).data();
			var tc = data.toggleclicked;
			$.proxy(funcs[tc], this)();
			data.toggleclicked = (tc + 1) % 2;
		});
		return this;
	};
}($));

$(document).ready(function() {
	$('.main-menu > ul > li').hover(function() {
		var classMenu = '';
		if ($(this).attr('class') == 'systems') {
			classMenu = 'right-menu';
			$('.language').addClass('hidden');
		}
		if ($(this).attr('class') == 'projects' || $(this).attr('class') == 'products') {
			classMenu = 'left-menu';
		}
		if (classMenu) {
			$('.menu-container').addClass(classMenu);
			$('a', this ).css('color', 'white');
			$(this ).css('color', 'white');
		}
	}, function() {
		$('.menu-container').removeClass('left-menu');
		$('.menu-container').removeClass('right-menu');
		$('.language').removeClass('hidden');
		$('a', this ).css('color', 'black');
		$(this ).css('color', 'black');
	});

	$('.menutoggle').click(function(event) {
		event.preventDefault();
		$('#mobile-menu').toggle();
	});

	$('.contact-links a').click(function() {
		$('.contact-links a').removeClass('active');
		$(this).addClass('active');
	});
});

$(".home-slider").slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 4000,
	speed: 1000,
	fade: true
});
